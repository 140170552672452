<div class="panel-container">
  <div class="panel-details">
    <div class="about-me card-view">
      <div class="line start">me = {{ "{" }}</div>
      <div class="line">
        &nbsp;&nbsp;&nbsp;&nbsp;name: &nbsp;<span>Weixin</span>,
      </div>
      <div class="line">
        &nbsp;&nbsp;&nbsp;&nbsp;location: &nbsp;<span>Toronto</span>,
      </div>
      <div class="line">
        &nbsp;&nbsp;&nbsp;&nbsp;resume: &nbsp;<a style="color: #454545"
          ><span (click)="viewResume()"> View</span></a
        >,
      </div>
      <div class="line">
        &nbsp;&nbsp;&nbsp;&nbsp;skills: &nbsp;<span
          >[ Javascript, HTML & CSS, SQL/NoSQL, Swift, Java, Kotlin, Python,
          C++, C#, Assembly ]</span
        >,
      </div>
      <div class="line">
        &nbsp;&nbsp;&nbsp;&nbsp;frameworks: &nbsp;<span
          >[ Angular, React / React Native, ExpressJS, HapiJS, Apollo Server,
          .NET ]</span
        >,
      </div>
      <div class="line">
        &nbsp;&nbsp;&nbsp;&nbsp;more: &nbsp;<span
          >[ Git, Linux, RESTful, Volt, Tensorflow, PostGIS, Bigtable, Android,
          IOS, GraphQL ]</span
        >
      </div>
      <div class="line end">{{ "}" }}</div>
    </div>
  </div>
  <div #mapContainer id="map"></div>
</div>
