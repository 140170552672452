import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss", "./contactPage.component.scss"],
})
export class ContactComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  scrollTo(id: string) {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
    return false;
  }
}
