<div class="nav-container">
  <nav class="sidenav">
    <section class="sidenav-content-top">
      <div class="image-container">
        <a class="card clickable">
          <div class="card-img">
            <img src="../../assets/img/profile.jpg" />
          </div>
          <div class="card-block">
            <p class="card-text">
              Ryan Weixin Wu
            </p>
          </div>
        </a>
      </div>
    </section>
    <section class="sidenav-content">
      <a
        (click)="scrollTo(1, 'aboutMe')"
        class="nav-link"
        [ngClass]="{ active: selectedSection === 1 }"
      >
        About
      </a>
      <a
        (click)="scrollTo(2, 'experience')"
        [ngClass]="{ active: selectedSection === 2 }"
        class="nav-link"
      >
        Experiences
      </a>
      <a
        (click)="scrollTo(3, 'project')"
        [ngClass]="{ active: selectedSection === 3 }"
        class="nav-link"
      >
        Projects
      </a>
      <a
        (click)="scrollTo(4, 'contact')"
        [ngClass]="{ active: selectedSection === 4 }"
        class="nav-link"
      >
        Contact
      </a>
    </section>
  </nav>
</div>
