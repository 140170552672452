<div class="panel-container">
  <div class="panel-details">
    <div class="marvel-device iphone4s silver">
      <div class="top-bar"></div>
      <div class="sleep"></div>
      <div class="volume"></div>
      <div class="camera"></div>
      <div class="sensor"></div>
      <div class="speaker"></div>
      <div class="screen">
        <div class="top-toolbar">
          <div class="back-btn" (click)="scrollTo('aboutMe')">
            &nbsp;&lt;&nbsp;back
          </div>
          <div class="title">Profile</div>
          <div class="right"></div>
        </div>
        <div class="content">
          <div class="top-banner"></div>

          <div class="profile-row">
            <div class="profile-pic">
              <div class="card-img">
                <img src="../../assets/img/profile2.jpeg" />
              </div>
            </div>
            <div class="name-email">
              <div class="name">Ryan Wu</div>
              <div class="position">Software Developer</div>
              <div class="addr">Greater Toronto Area (GTA)</div>
            </div>
          </div>
          <div class="contact-info">
            <div class="btn-phone">
              <span>&#9742; (647) 609 9933</span>
            </div>

            <div class="btn-email">
              <span>&#9993; wuweixin6@gmail.com</span>
            </div>
          </div>
          <div class="links">
            <a
              href="https://www.linkedin.com/in/weixin-wu/"
              target="_blank"
              class="fa fa-linkedin"
              title="linkedin"
            ></a>
            <a
              href="https://docs.google.com/document/d/e/2PACX-1vRqkqUTIjcn7L_m333QKQlm4XEy80vnQ971XTS0OgTPNlE5kZLcbQZhyJjI1smsehpYhF5wPxxnVBUv/pub"
              target="_blank"
              class="fa fa-file-text-o"
              title="resume.pdf"
            ></a>
            <a
              href="#"
              class="fa fa-arrow-up"
              (click)="scrollTo('aboutMe')"
              title="back to top"
            ></a>
          </div>
        </div>
      </div>
      <div class="home"></div>
      <div class="bottom-bar"></div>
    </div>
  </div>
</div>
