import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
})
export class NavComponent implements OnInit {
  constructor() {}
  selectedSection = 1;
  ngOnInit() {}
  scrollTo(sectionNumber: number, id: string) {
    this.selectedSection = sectionNumber;
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}
