<div class="card">
  <div class="card-header">
    {{ title }}
  </div>
  <div
    class="card-block"
    (mouseleave)="setDescriptionVisibility(false)"
    (mouseenter)="setDescriptionVisibility(true)"
  >
    <div class="description" *ngIf="showDescription">
      <p>{{ description }}</p>
    </div>
    <div class="card-img" style="overflow: hidden;">
      <img
        src="{{ imageUrl }}"
        alt="screenshot"
        [ngStyle]="{ height: showLargeImage ? '28rem' : '20rem' }"
      />
    </div>
  </div>
  <div class="card-footer">
    <button
      class="btn btn-sm btn-link"
      [disabled]="appLink === ''"
      (click)="viewApp()"
      [ngStyle]="{ 'font-size': appLink === '' ? '0.5rem' : '0.8rem' }"
    >
      {{ appLink !== "" ? "view" : "Not available online" }}
    </button>
  </div>
</div>
