<div class="panel-container">
  <div class="panel-details">
    <div class="card-view">
      <div class="education">
        <h2>Education</h2>
        <h3>Bachelor of Applied Science, University of Toronto</h3>
        <h3>Computer Engineering</h3>
      </div>
      <div class="bottom-bar"></div>
    </div>
  </div>
</div>
