import { Component, AfterViewInit, ElementRef, ViewChild } from "@angular/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  title = "angular-gmap";
  // @ViewChild("mapContainer", { static: false }) gmap: ElementRef;
  // map: google.maps.Map;
  // lat = 40.73061;
  // lng = -73.935242;

  // coordinates = new google.maps.LatLng(this.lat, this.lng);

  // mapOptions: google.maps.MapOptions = {
  //   center: this.coordinates,
  //   zoom: 8
  // };

  // marker = new google.maps.Marker({
  //   position: this.coordinates,
  //   map: this.map
  // });

  // ngAfterViewInit() {
  //   this.mapInitializer();
  // }

  // mapInitializer() {
  //   this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
  //   this.marker.setMap(this.map);
  // }
  scrollTo(id: string) {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  showResume() {
    const url =
      "https://docs.google.com/document/d/e/2PACX-1vRqkqUTIjcn7L_m333QKQlm4XEy80vnQ971XTS0OgTPNlE5kZLcbQZhyJjI1smsehpYhF5wPxxnVBUv/pub";
    window.open(url, "_blank");
  }
}
