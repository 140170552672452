<div class="panel-container">
  <div class="panel-details">
    <div class="about-me card-view">
      <h2>Some of my personal projects</h2>
      <div class="project-container">
        <div class="clr-row">
          <div class="clr-col-12 clr-col-md-6 clr-col-xl-4">
            <app-project-card
              title="Sudoku App"
              imageUrl="assets/img/sudoku.png"
              description="Sudoku Puzzle app.
              Hundreds of puzzles with four different difficulties.
              Also has a built-in puzzle solver that can solve any valid
              Sudoku puzzles."
              appLink="https://play.google.com/store/apps/details?id=com.weixin.sudoku"
            ></app-project-card>
          </div>
          <div class="clr-col-12 clr-col-md-6 clr-col-xl-4">
            <app-project-card
              title="TTC Next (2018)"
              imageUrl="assets/img/ttcnext.png"
              description="Get real-time buses and streetcars arrival predictions for TTC.
              It also includes useful features like:
              - Predictions for nearby stops.
              - Save frequently accessed stops,
              - Google Street View and Maps for stops."
              appLink="https://play.google.com/store/apps/details?id=com.iceboat.ttc"
            ></app-project-card>
          </div>
          <div class="clr-col-12 clr-col-md-6 clr-col-xl-4">
            <app-project-card
              title="Movie review (2017)"
              description="Moview review and recommandation App using TMDb APIs. "
              imageUrl="assets/img/movieReview.png"
            ></app-project-card>
          </div>

          <div class="clr-col-12 clr-col-md-6 clr-col-xl-4">
            <app-project-card
              title="Dark Weather (2017)"
              description="Weather forecast App Using React Native "
              imageUrl="assets/img/darkWeather.png"
            ></app-project-card>
          </div>
          <div class="clr-col-12 clr-col-md-6 clr-col-xl-4">
            <app-project-card
              title="Remote Plus (2016)"
              description="This app synchronizes all your text files and folders between your mobile phone and desktop PC.  You can take notes on your computer and view/edit it on your phone or vice versa."
              imageUrl="assets/img/remoteplus.png"
            ></app-project-card>
          </div>
          <div class="clr-col-12 clr-col-md-6 clr-col-xl-4">
            <app-project-card
              title="Raise to wake Android (2017)"
              description="Android background service that wake the phone when a user picks up the phone."
              imageUrl="assets/img/raisetowake.gif"
            ></app-project-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-details">
    <div class="about-me card-view">
      <h2>Others</h2>
      <div class="clr-col-12 clr-col-lg-12 clr-col-xl-12">
        <app-project-card
          title="Online Teaching & Reviewing website (2020)"
          imageUrl="assets/img/quanminedu.png"
          showLargeImage="true"
          appLink="https://festive-jennings-011dc0.netlify.app/"
          description="
          Online Teaching & Reviewing website.
          - Tech stack: Express, Typescript, MongoDB, and React.
          - Various functionalities including course searching, tutor rating, course booking and purchasing, online lecture uploading and streaming, question forum and more.
          - Designed and developed backend services from scratch.
          "
        ></app-project-card>
      </div>
    </div>
  </div>
</div>
