<div class="header-content-container">
  <header class="header header-6">
    <div class="header-actions">
      <a class="nav-link nav-text" (click)="scrollTo('aboutMe')"> About Me </a>
      <a class="nav-link nav-text" (click)="scrollTo('experience')">
        Experiences
      </a>
      <a class="nav-link nav-text" (click)="scrollTo('project')"> Projects </a>
      <a class="nav-link nav-text" (click)="scrollTo('contact')"> Contact </a>
      <a class="nav-link nav-text" (click)="showResume()"> Resume </a>
    </div>
  </header>

  <div class="content-container">
    <div class="content-area">
      <section id="aboutMe">
        <app-main-panel></app-main-panel> <app-education></app-education>
      </section>
      <section id="experience">
        <app-experience></app-experience>
      </section>
      <section id="project">
        <app-projects></app-projects>
      </section>
      <section id="contact">
        <app-contact></app-contact>
      </section>
    </div>
  </div>
</div>
