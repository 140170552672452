import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-experience",
  templateUrl: "./experience.component.html",
  styleUrls: ["./experience.component.scss"],
})
export class ExperienceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  scrollTo() {
    const section = document.getElementById("contact");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}
