import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { styledMapType } from "src/assets/style/map";

@Component({
  selector: "app-main-panel",
  templateUrl: "./main-panel.component.html",
  styleUrls: ["./main-panel.component.scss"],
})
export class MainPanelComponent implements OnInit {
  aboutMe = ``;
  currentTypeWriterIndex = 0;

  constructor() {}

  ngOnInit(): void {
    this.currentTypeWriterIndex = 0;
  }

  typeWriter(): void {
    const speed = 50;
    const txt = "Lorem ipsum typing effect!";
    console.log("in the typewriter", this.currentTypeWriterIndex, txt.length);
    if (this.currentTypeWriterIndex < txt.length) {
      this.aboutMe += txt.charAt(this.currentTypeWriterIndex);
      console.log(this.aboutMe, "===");
      this.currentTypeWriterIndex++;
      setTimeout(this.typeWriter, speed);
    }
  }

  viewResume() {
    window.open("https://weixinwu.herokuapp.com/src/pdf/Resume.pdf", "_blank");
  }
}
